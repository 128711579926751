import { Link, navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Faq } from 'src/components/LandingPage';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';
import { useTheme } from 'src/hooks';
import { useFaq } from 'src/hooks/componentData';

/* eslint-disable react/prop-types */
function WebformThankYouPage({ location }) {
  const faq = useFaq('faq');
  const t = useTheme();

  /**
   *  If user is navigated to this page after successful webform submission than render page normally,
   *  in any other scenario, redirect user to home page.
   */
  useEffect(() => !location?.state?.fromWebform && navigate('/'), [location]);

  return (
    <PageLayout>
      <PageSEO title="Köszönjük" description="Köszönjük" location={location}>
        {/* This page should not be indexed */}
        <meta name="robots" content="noindex" />
      </PageSEO>
      <Container fluid className="bg-shapes-banner px-0 pt-7">
        <Container className="py-8">
          <Row className="align-items-center">
            <Col xs={12} md={8} lg={5} className="mb-3">
              <h1 className="h2 mb-4">{t.pages.thankYou.title}</h1>
              <p className="text-muted mb-5">{t.pages.thankYou.description}</p>
              <Link to="/" className="btn btn-gradient-primary">
                {t.pages.thankYou.buttonLabel}
              </Link>
            </Col>
            <Col xs={12} lg={7} className="d-none d-lg-block">
              <div className="mw-sm mx-auto">
                <StaticImage src="../images/thank-you.png" alt="image" />
              </div>
            </Col>
          </Row>
        </Container>
        {faq && <Faq {...faq} />}
      </Container>
    </PageLayout>
  );
}

export default WebformThankYouPage;
