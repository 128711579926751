import { graphql, useStaticQuery } from 'gatsby';

function getData(data, name) {
  const faq = data[name];

  if (faq === null) {
    return null;
  }

  return {
    faq: {
      id: faq._id,
      title: faq.title,
      tagline: faq.tagline,
      description: faq.description,
      items: faq.items,
    },
  };
}

function useFaq(name) {
  const data = useStaticQuery(graphql`
    {
      faq: sanityFaqDocument(_id: { eq: "cb35dd3a-ca13-4a73-9d1b-72fd4ccebbba" }) {
        ...getFaq
      }
    }
  `);

  return getData(data, name);
}

export default useFaq;
